import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import Loader from 'components/Loader';
import './DeleteDialog.scss';

function DeleteDialog(props) {
  const { onConfirmation, onCancel, listName, isLoading } = props;

  const handleConfirmation = () => {
    onConfirmation();
    document.body.classList.remove('p-overflow-hidden');
  };

  const handleCancel = () => {
    onCancel();
    document.body.classList.remove('p-overflow-hidden');
  };

  return (
    <Dialog
      modal
      visible
      onHide={handleCancel}
      draggable={false}
      className="delete-dialog-main"
      blockScroll
    >
      {isLoading ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : (
        <div>
          <div className="main-header">
            <p className="header">Delete site list</p>
            <p className="sub-header-delete" data-testid="sub-header1">
              Are you sure you want to delete <span>‘{listName}’ </span>
              list? This will permanently remove the entire list along with all
              the bookmarked sites inside it. This action cannot be undone.
            </p>
            <div className="button-section-details">
              <button
                type="submit"
                className="confirm-btn"
                onClick={handleConfirmation}
                label="Remove"
                data-testid="confirm-btn"
                onKeyDown={() => {
                  // Key down
                }}
              >
                Delete
              </button>
              <button
                className="cancel-btn"
                type="submit"
                onClick={handleCancel}
                onKeyDown={() => {
                  // Key down
                }}
                data-testid="cancel-button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  listName: PropTypes.string.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

DeleteDialog.defaultProps = {
  isLoading: false,
};

export default DeleteDialog;
