import Axios from 'axios';

class UpdateListNameHttpRequest {
  RESOURCE_PATH = `site-health/list-name`;

  _oldName = null;

  _newName = null;

  constructor(oldName, newName) {
    this._oldName = oldName;
    this._newName = newName;
  }

  async updatelistName() {
    const path = `${this.RESOURCE_PATH}?oldName=${encodeURIComponent(
      this._oldName,
    )}&newName=${encodeURIComponent(this._newName)}`;

    const response = await Axios.put(path);
    return response;
  }
}

export default UpdateListNameHttpRequest;
