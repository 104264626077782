import React, { useState, useCallback } from 'react';
import './editMySite.scss';
import mySiteHealthDeletePlaylistHttpRequest from 'store/thunks/06_mySiteHealthDeletePlaylist';
import {
  vectorIcon,
  activeEdit,
  trash,
  disabledEdit,
  trashHover,
  editHover,
} from 'assets/icons';
import DeleteDialog from 'components/DeleteDialog/DeleteDialog';
import { SiteHealthListHttpRequest } from 'dashboards/SiteHealth/store/thunks';
import SiteCountHttpRequest from 'dashboards/SiteHealth/store/thunks/06_SiteCount.thunk';
import { mySiteHealthBookmarkHttpRequest } from 'store/thunks';
import { useSelector, shallowEqual } from 'react-redux';
import { siteHealthStore } from 'dashboards/SiteHealth/store';
import { preferencesConfigAction } from 'store/actions';
import { compassStore } from 'store';
import {
  MY_SITE_HEALTH_SECTION_ID,
  USER_PLAYLIST_SECTION_ID,
} from 'dashboards/SiteHealth/constants';
import { SITE_HEALTH_MODULE } from 'constants/savedFilters/savedFilters.constants';
import mySiteHealthSelector from './selector/mySiteHealthSelector';

function EditMySite(rowData) {
  const { dispatch } = compassStore;
  const { dispatch: siteHealthDispatch } = siteHealthStore;

  const { Name } = rowData;

  const [selectedPlaylist, setSelectedPlaylist] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [editHovered, setEditIsHovered] = useState(false);

  const setBlockPreferences = (type, value, sectionId) => {
    const preferencesConfig = { type, value, sectionId };
    dispatch(preferencesConfigAction(preferencesConfig));
  };

  const siteHealthBlockPreferences = (type, value, sectionId) => {
    const preferencesConfig = { type, value, sectionId };
    siteHealthDispatch(preferencesConfigAction(preferencesConfig));
  };

  const { edit } = useSelector(mySiteHealthSelector, shallowEqual);

  const editName = (name) => {
    setBlockPreferences('EDIT', true, MY_SITE_HEALTH_SECTION_ID);
    setBlockPreferences('NAME', name, MY_SITE_HEALTH_SECTION_ID);
    setBlockPreferences('OLD_NAME', name, MY_SITE_HEALTH_SECTION_ID);
  };

  const handleDelete = useCallback((name) => {
    setBlockPreferences('EDIT', false, MY_SITE_HEALTH_SECTION_ID);
    setBlockPreferences('NAME', '', MY_SITE_HEALTH_SECTION_ID);
    setBlockPreferences('OLD_NAME', '', MY_SITE_HEALTH_SECTION_ID);
    setSelectedPlaylist(name);
    setShowDialog(true);
  }, []);

  // Cancel of Dialog
  const onCancelCancelation = useCallback(() => {
    setShowDialog(false);
  }, []);

  const onConfirmation = useCallback(async () => {
    setIsLoading(true);
    setShowDialog(true);

    // Call delete playlist API request and wait for completion
    try {
      await mySiteHealthDeletePlaylistHttpRequest({
        dataTable: SITE_HEALTH_MODULE,
        playlistName: selectedPlaylist,
      });
      await dispatch(mySiteHealthBookmarkHttpRequest());

      siteHealthBlockPreferences(
        'TOGGLE_SWITCH',
        false,
        USER_PLAYLIST_SECTION_ID,
      );
      siteHealthBlockPreferences('SELECTED_LIST', '', USER_PLAYLIST_SECTION_ID);
    } catch (error) {
      // Mock
    } finally {
      setIsLoading(false);
      setShowDialog(false);
      siteHealthDispatch(SiteHealthListHttpRequest('', { playlist: '' }));
      siteHealthDispatch(SiteCountHttpRequest('', { playlist: '' }));
    }
  }, [selectedPlaylist, dispatch]);

  const getEditIcon = () => {
    if (edit) return disabledEdit;
    if (editHovered) return editHover;
    return activeEdit;
  };

  return (
    <>
      <div className="main-site">
        <div className="main-edit-button">
          <img
            src={vectorIcon}
            alt="vectorIcon"
            role="presentation"
            data-testid="vectorIcon-icon"
          />
          <div
            className={`edit-button ${!edit ? 'cursor-pointer' : ''}`}
            data-testid="edit-button"
            onClick={() => !edit && editName(Name)}
            onKeyDown={() => {
              // Mock
            }}
            onMouseEnter={() => setEditIsHovered(true)}
            onMouseLeave={() => setEditIsHovered(false)}
          >
            <img
              className="active-edit"
              src={getEditIcon()}
              alt="active-edit"
              role="presentation"
            />
            <p className={`${edit ? 'disabled' : 'enabled'}`}>Edit name</p>
          </div>
          <img
            src={vectorIcon}
            alt="vectorIcon"
            role="presentation"
            data-testid="vectorIcon-icon"
          />
        </div>
        <img
          src={isHovered ? trashHover : trash}
          onClick={() => handleDelete(Name)}
          className="trash"
          alt="vectorIcon"
          role="presentation"
          data-testid="delete-icon"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
      </div>
      {showDialog && (
        <DeleteDialog
          isLoading={isLoading}
          listName={selectedPlaylist}
          onConfirmation={onConfirmation}
          onCancel={onCancelCancelation}
        />
      )}
    </>
  );
}

export default EditMySite;
