import { TIMELINE_ITEMS } from '../../../../../../constants';

const COMMON_TABLE_ITEMS = {
  actualVsBaseline: {
    code: 'actualVsBaseline',
    label: 'Actual vs Baseline plan difference (days)',
    type: 'CALCULATED',
  },
  actualVsCurrentPlan: {
    code: 'actualVsCurrentPlan',
    label: 'Actual vs Current plan difference (days)',
    type: 'CALCULATED',
  },
  baselineVsCurrentPlan: {
    code: 'baselineVsCurrentPlan',
    label: 'Baseline vs Current plan difference (days)',
    type: 'CALCULATED',
  },
  currentPlanVsActual: {
    code: 'currentPlanVsActual',
    label: 'Current vs Actual plan difference (days)',
    type: 'CALCULATED',
  },
  baselineVsActual: {
    code: 'baselineVsActual',
    label: 'Baseline vs Actual plan difference (days)',
    type: 'CALCULATED',
  },
};

export const TABLE_DATA_STRUCTURE = {
  baselineMilestoneDate: {
    code: 'baselineMilestoneDate',
    label: 'Baseline Plan',
    type: 'RAW',
  },
  plannedMilestoneDate: {
    code: 'plannedMilestoneDate',
    label: 'Current Plan',
    type: 'RAW',
  },
  actualMilestoneDate: {
    code: 'actualMilestoneDate',
    label: 'Actual',
    type: 'RAW',
  },
  currentPlanVsBaseline: {
    code: 'currentPlanVsBaseline',
    label: 'Current vs Baseline plan difference (days)',
    type: 'CALCULATED',
  },
  actualVsBaseline: {
    code: 'actualVsBaseline',
    label: 'Actual vs Baseline plan difference (days)',
    type: 'CALCULATED',
  },
  ...COMMON_TABLE_ITEMS,
};

export const COMPLEX_TABLE_DATA_STRUCTURE = {
  baselineMilestoneDate: {
    code: 'baselineMilestoneDate',
    label: TIMELINE_ITEMS.BASELINED,
    type: 'RAW',
  },
  plannedMilestoneDate: {
    code: 'plannedMilestoneDate',
    label: TIMELINE_ITEMS.PLANNED,
    type: 'RAW',
  },
  actualMilestoneDate: {
    code: 'actualMilestoneDate',
    label: TIMELINE_ITEMS.ACTUAL_AND_FORECAST,
    type: 'RAW',
  },
  currentPlanVsBaseline: {
    code: 'currentPlanVsBaseline',
    label: 'Current vs Baseline plan difference (days)',
    type: 'CALCULATED',
  },
  ...COMMON_TABLE_ITEMS,
};
