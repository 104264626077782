const INITIAL_STATE = {
  preferencesConfigMap: {
    MY_TRAILS_SECTION_ID: {
      search: '',
    },
    MY_SITE_HEALTH_SECTION_ID: {
      PLAYLIST_LIST_NAME: [],
      EDIT: false,
      NAME: '',
      OLD_NAME: '',
    },
  },
};

export default INITIAL_STATE;
