import { PortfolioThunkGenerator } from 'utility/redux/thunkGenerator';

const portfolioFiltersDataHttpRequest = (() => {
  const apiName = 'portfolioFilters';
  const generatedHttpThunk = new PortfolioThunkGenerator(apiName);
  generatedHttpThunk.resource = `trial-management/cascading-filter`;
  return generatedHttpThunk.generateThunk();
})();

export default portfolioFiltersDataHttpRequest;
