import { isNil } from 'lodash';

const transformChartData = (data) => {
  const { timelineItems } = data;
  const filteredTimelineItems = timelineItems.filter(
    (record) => !isNil(record.milestoneName),
  );
  const transformedChartData = filteredTimelineItems.reduce(
    (accumulator, record) => {
      accumulator.baselineMilestoneData.push({
        milestoneDate: +new Date(record.baselineMilestoneDate),
        milestoneName: record.milestoneName,
        timelineId: 'BASELINED_MILESTONE',
        ragStatus: record.ragStatus,
        milestoneDateString: record.baselineMilestoneDate,
      });
      accumulator.plannedMilestoneData.push({
        milestoneDate: +new Date(record.plannedMilestoneDate),
        milestoneName: record.milestoneName,
        timelineId: 'PLANNED_MILESTONE',
        ragStatus: record.ragStatus,
        milestoneDateString: record.plannedMilestoneDate,
      });
      accumulator.actualMilestoneData.push({
        milestoneDate: +new Date(record.actualMilestoneDate),
        milestoneName: record.milestoneName,
        timelineId: 'ACTUAL_MILESTONE',
        ragStatus: record.ragStatus,
        milestoneDateString: record.actualMilestoneDate,
      });
      return accumulator;
    },
    {
      baselineMilestoneData: [],
      plannedMilestoneData: [],
      actualMilestoneData: [],
    },
  );
  return transformedChartData;
};

export default transformChartData;
