export const SITE_HEALTH_PERFORMANCE_SECTION_ID =
  'SITE_HEALTH_PERFORMANCE_SECTION_ID';

export const PROTOCOL_DEVIATIONS_SECTION_ID = 'PROTOCOL_DEVIATIONS_SECTION_ID';

export const SITE_HEALTH_OVERVIEW_SECTION_ID =
  'SITE_HEALTH_OVERVIEW_SECTION_ID';

export const GLOBAL_FILTER_SECTION_ID = 'GLOBAL_FILTER_SECTION';

export const SITE_INFORMATION_TRIALS_SECTION_ID =
  'SITE_INFORMATION_TRIALS_SECTION_ID';

export const SITE_INFO_KEY_PRINCIPAL_INVESTIGATORS_SECTION_ID =
  'SITE_INFO_KEY_PRINCIPAL_INVESTIGATORS_SECTION_ID';

export const EDC_SITE_READINESS_SECTION_ID = 'EDC_SITE_READINESS_SECTION';

export const USER_PLAYLIST_SECTION_ID = 'USER_PLAYLIST_SECTION_ID';

export const MY_SITE_HEALTH_SECTION_ID = 'MY_SITE_HEALTH_SECTION_ID';

export const PAGE_SIZE = 10;
