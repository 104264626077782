import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DashboardRoute from 'pages/routes/routes';
import { LoginCallback } from '@okta/okta-react';
import useScrollToTopOnRouteChange from 'utility/hooks/useScrollToTopOnRouteChange';
import AnalyticsProvider from 'providers/AnalyticProvider';
import SecureRoute from '../../components/okta/SecureRoute';
import NavigationBar from '../../section/01_NavigationBarSection/NavigationBar.section';
import './_dashboard.scss';
// import ErrorComponent from './utility/ErrorComponent';

function Dashboards() {
  useScrollToTopOnRouteChange();
  /*
  Commenting for future reference
  const navigate = useNavigate();
  
  const restoreOriginalUri = async (_, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  */

  return (
    <AnalyticsProvider>
      <div>
        <Routes>
          <Route
            path="/login/callback"
            // errorComponent={ErrorComponent}
            element={<LoginCallback />}
          />
        </Routes>

        <SecureRoute>
          <div className="row compass-nav-holder">
            <NavigationBar />
          </div>
          <div className="row compass-content" data-testid="content-root">
            <div className="col-12">
              <DashboardRoute />
            </div>
          </div>
        </SecureRoute>
      </div>
    </AnalyticsProvider>
  );
}

export default Dashboards;
