import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { mySiteHealthBookmarkHttpRequest } from 'store/thunks';
import Loader from 'components/Loader';
import { preferencesConfigAction } from 'store/actions';
import {
  MY_SITE_HEALTH_SECTION_ID,
  USER_PLAYLIST_SECTION_ID,
} from 'dashboards/SiteHealth/constants';
import {
  SiteHealthFiltersHttpRequest,
  SiteHealthListHttpRequest,
} from 'dashboards/SiteHealth/store/thunks';
import { SITE_HEALTH_MODULE } from 'constants/savedFilters/savedFilters.constants';
import SiteCountHttpRequest from 'dashboards/SiteHealth/store/thunks/06_SiteCount.thunk';
import { siteHealthStore } from 'dashboards/SiteHealth/store';
import { compassStore } from 'store';
import { check, cross, vectorIcon } from 'assets/icons';
import './MySitesHealth.section.scss';
import mySiteHealthSelector from './selector/mySiteHealthSelector';
import UpdateListNameHttpRequest from './utility/UpdateListName.utility';

function EditName({ rowData, setSidebarVisible }) {
  const { dispatch } = compassStore;
  const { dispatch: siteHealthDispatch } = siteHealthStore;

  // const { rowData.Name } = rowData;
  const [inputValue, setInputValue] = useState(rowData.Name);
  const [showDialog, setShowDialog] = useState(false);

  const { edit, name, oldName } = useSelector(
    mySiteHealthSelector,
    shallowEqual,
  );

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const setBlockPreferences = (type, value, sectionId) => {
    const preferencesConfig = { type, value, sectionId };
    dispatch(preferencesConfigAction(preferencesConfig));
  };

  const siteHealthDispatchStore = (type, value, sectionId) => {
    const preferencesConfig = { type, value, sectionId };
    siteHealthDispatch(preferencesConfigAction(preferencesConfig));
  };

  const handleCross = () => {
    setBlockPreferences('EDIT', false, MY_SITE_HEALTH_SECTION_ID);
    setBlockPreferences('NAME', '', MY_SITE_HEALTH_SECTION_ID);
  };

  const handleSaveList = async () => {
    setShowDialog(true);
    const updateName = new UpdateListNameHttpRequest(oldName, inputValue);
    try {
      await updateName.updatelistName();
      siteHealthDispatchStore('TOGGLE_SWITCH', false, USER_PLAYLIST_SECTION_ID);
      siteHealthDispatchStore('SELECTED_LIST', '', USER_PLAYLIST_SECTION_ID);
      siteHealthDispatch(SiteHealthListHttpRequest('', { playlist: '' }));
      siteHealthDispatch(SiteCountHttpRequest('', { playlist: '' }));
    } catch (error) {
      // Mock
    } finally {
      setShowDialog(false);
      setBlockPreferences('EDIT', false, MY_SITE_HEALTH_SECTION_ID);
      setBlockPreferences('NAME', '', MY_SITE_HEALTH_SECTION_ID);
      setBlockPreferences('OLD_NAME', '', MY_SITE_HEALTH_SECTION_ID);
      await dispatch(mySiteHealthBookmarkHttpRequest());
    }
  };

  const handleOpenlist = (Name) => {
    siteHealthDispatchStore('TOGGLE_SWITCH', true, USER_PLAYLIST_SECTION_ID);
    siteHealthDispatchStore('SELECTED_LIST', Name, USER_PLAYLIST_SECTION_ID);
    siteHealthDispatch(SiteHealthListHttpRequest('', { playlist: Name }));
    siteHealthDispatch(SiteCountHttpRequest('', { playlist: Name }));
    siteHealthDispatch(
      SiteHealthFiltersHttpRequest({
        dataTable: SITE_HEALTH_MODULE,
        exclusive: JSON.stringify({ country_name: false }),
        filters: JSON.stringify({}),
        playlist: Name,
      }),
    );
    setSidebarVisible(false);
  };

  return (
    <div>
      {edit && name === rowData.Name ? (
        <div className="main-site-list">
          <InputText
            className="edit-list-name"
            value={inputValue}
            onChange={handleInputChange}
            id="search-input"
            data-testid="edit-list-name"
            placeholder="Enter list name"
          />
          <img
            src={check}
            onClick={
              inputValue && inputValue.length >= 3 ? handleSaveList : undefined
            }
            className={`${
              inputValue && inputValue.length >= 3 ? 'cursor-pointer' : ''
            }`}
            alt="check-icon"
            role="presentation"
            data-testid="check-icon"
          />
          <img
            src={vectorIcon}
            alt="vectorIcon"
            role="presentation"
            data-testid="vectorIcon-icon"
          />
          <img
            src={cross}
            onClick={handleCross}
            className="cursor-pointer"
            alt="cross-icon"
            role="presentation"
            data-testid="cross-icon"
          />
        </div>
      ) : (
        <p
          className="site-list-name cursor-pointer"
          data-testid="user-list-name"
          onKeyDown={() => {
            // Mock
          }}
          onClick={() => handleOpenlist(rowData.Name)}
        >
          {rowData.Name}
        </p>
      )}
      {showDialog && <Loader />}
    </div>
  );
}

EditName.propTypes = {
  rowData: PropTypes.shape({
    Name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  setSidebarVisible: PropTypes.func,
};

EditName.defaultProps = {
  setSidebarVisible: () => {},
};

export default EditName;
