import { get } from 'lodash';

const {
  MY_SITE_HEALTH_SECTION_ID,
} = require('dashboards/SiteHealth/constants');

const mySiteHealthSelector = (currentState) => {
  const sectionId = MY_SITE_HEALTH_SECTION_ID;
  const preferencesConfigMap =
    currentState.uiState.preferencesConfigMap[sectionId];

  const listName = get(preferencesConfigMap, 'PLAYLIST_LIST_NAME', null);
  const edit = get(preferencesConfigMap, 'EDIT', null);
  const name = get(preferencesConfigMap, 'NAME', null);
  const oldName = get(preferencesConfigMap, 'OLD_NAME', null);

  const returnData = { listName, edit, name, oldName };

  return returnData;
};

export default mySiteHealthSelector;
